<template>
    <button :class="['custom-button', colorClass]" :disabled="isDisabled">
        <div class="button-icons">
            <slot>
                <img v-show="icon" v-if="!isLoading" :src="icon" :alt="alt">
            </slot>
            <div v-if="isLoading" class="loader"></div>
        </div>
    </button>
</template>

<script>
export default {
    name: 'CustomButton',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: 'default'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            required: false
        },
        alt: {
            type: String,
            required: false,
            default: 'icono del boton'
        },
    },
    computed: {
        colorClass() {
            return `custom-button--${this.color}`;
        },
        isDisabled() {
            return this.disabled || this.isLoading;
        },
    }
};
</script>

<style scoped>
.custom-button {
    border: 0px;
    width: 50px;
    height: 50px;
    display: flex;
    cursor: pointer;
    font-size: 24px;
    font-weight: 500;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.button-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    border: 4px solid #ff6900;
    border-top: 4px solid transparent;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    animation: spin 2s linear infinite;
}

.button.loading {
    cursor: not-allowed;
}

.custom-button--default {
    background-color: transparent;
}

.custom-button--default:hover {
    background-color: #ffffffdc;
}

.custom-button--orange {
    background-color: #FF5E00;
}

.custom-button--orange:hover {
    background-color: #ff6f1c;
}

.custom-button--black {
    background-color: #000000;
}

.custom-button--black:hover {
    background-color: #2b2929;
}

.custom-button:disabled {
    cursor: not-allowed;
    opacity: 70%;
}



/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
