<template>
    <div class="video-container">
      <video ref="videoPlayer" :src="computedSrc" @play="handlePlay" @pause="handlePause" @ended="handleEnded"
            :controls="controls" :autoplay="autoplay" :loop="loop" muted></video>
    </div>
  </template>

<script>
export default {
    name: 'VideoSetter',
    props: {
        deviceId: {
            type: String,
            default: null
        },
        src: {
            type: String,
            default: null
        },
        controls: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        },
        onPlay: {
            type: Function,
            default: () => { }
        },
        onPause: {
            type: Function,
            default: () => { }
        },
        onEnded: {
            type: Function,
            default: () => { }
        },
    },
    data() {
        return {
            stream: null,
        };
    },
    computed: {
        computedSrc() {
            return this.src ? this.src : (this.deviceId ? null : this.src);
        }
    },
    watch: {
        src(newSrc) {
            if (newSrc) {
                this.clearStream();
            }
        },
        deviceId: {
            immediate: true,
            handler(newDeviceId) {
                if (newDeviceId && !this.src) {
                    this.setupCameraStream(newDeviceId);
                } else {
                    this.clearStream();
                }
            }
        },
    },
    mounted() {
        this.$emit('video-ref-ready', this.$refs.videoPlayer);
    },
    methods: {
        async setupCameraStream(deviceId) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: { deviceId: { exact: deviceId } },
                    audio: false
                });
                this.stream = stream;
                this.$refs.videoPlayer.srcObject = stream;
                this.$emit('stream-ready', stream);  // Emitir el stream al componente padre
            } catch (error) {
                console.error('Error al obtener el stream de la cámara:', error);
            }
        },
        clearStream() {
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
                this.stream = null;
                this.$refs.videoPlayer.srcObject = null;
            }
        },
        beforeDestroy() {
            this.clearStream();
        },
        handlePlay() {
            this.onPlay();
        },
        handlePause() {
            this.onPause();
        },
        handleEnded() {
            this.onEnded();
        },
    }
};
</script>

<style scoped>
.video-container {
    display: flex;
    width: 100%;
    height: 100%;
}

video {
    width: 100%;
    border-radius: 10px;
    background-color: #949393;
}

@media (max-width: 1250px) {
    .video-container {
        overflow: hidden;
        justify-content: center;
    }

    video {
        width: auto;
        height: 389px;
    }
}

@media (max-width: 1000px) {
    .video-container {
        width: 100%;
        height: fit-content;
        justify-content: center;
    }

    video {
        width: 100%;
        height: auto;
    }
}
</style>
