import { saveRecordingToServer, stopRecordingAsPromise } from '@/services/CameraService'

export default {
  async startRecording({ state, commit }) {
    if (!state.cameraStream || state.cameraStream.getTracks().length === 0) {
      console.error('No hay un stream activo para grabar.');
      return;
    }

    state.isPaused = false;
    commit('clearVideoUpload')
    state.recordedChunks= [];
    state.cameraRecorder = new MediaRecorder(state.cameraStream);

    state.cameraRecorder.ondataavailable = event => {
      if (event.data.size > 0) {
        state.recordedChunks.push(event.data);
      }
    };

    state.cameraRecorder.onstop = () => {
      console.debug(state.srcVide, 'srcVide')
      if (!state.isCancell) {
        const videoFile = new Blob(state.recordedChunks, { type: 'video/webm' });
        const result = saveRecordingToServer(videoFile, true);
        return result;
      } else {
        commit('cleanCameraStream')
  }
    };

    state.cameraRecorder.start();
    commit('setIsRecording', true)
  },
  async sendVideoSigns({ state, commit }) {
    try {
      var result;
      if (state.fileVideo) {
        result = await saveRecordingToServer(state.fileVideo);
      }
      if (state.cameraRecorder && state.isRecording) {
        result = await stopRecordingAsPromise(state);
      }

      if (!result || result.error) {
        throw new Error(result.error);
      }

      commit('setErrorMessage', null);
      commit('clearVideoUpload');
      commit('setSignsTranslate', result.message)

      return result;

    } catch (error) {
      console.debug(error)
      commit('clearVideoUpload');
      commit('setErrorMessage', error.message || 'Error al enviar el video');

      return { error: error.message };
    }


  },
  resetRecording({ state, commit }) {
    commit('clearVideoUpload');
    if (state.isRecording) {
      state.cameraRecorder.stop();
    }
  },
  stopRecording({ state, commit }) {
    if (state.cameraRecorder && state.isRecording) {
      state.cameraRecorder.stop();
      commit('setIsRecording', false);
    }
  },
  pauseRecording({ state }) {
    console.debug('pauseRecording')
    if (state.srcVideo) {
      if (state.videoRef) {
        state.videoRef.pause();
      }
    } else {
      if (state.cameraRecorder && state.isRecording && !state.isPaused) {
        state.cameraRecorder.pause();
      }
    }
    state.isPaused = true;
  },
  resumeRecording({ state }) {
    console.debug('resumeRecording')
    if (state.srcVideo) {
      if (state.videoRef) {
        state.videoRef.play();
      }
    } else {
      if (state.cameraRecorder && state.isRecording && state.isPaused) {
        state.cameraRecorder.resume();
      }
    }
    state.isPaused = false;
  },
  setCameraDeviceId({ commit }, deviceId) {
    commit('setCameraDeviceId', deviceId)
  },
  allowEditMessage({ commit }) {
    console.debug('sdsd')
    commit('setActiveEdit', false)
  },
  copyTextTranslate({state}) {
    navigator.clipboard.writeText(state.signsTranslate);
  },
}