<template>
  <main class="main-content">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: 'MainGrid',
};
</script>

<style scoped>
.main-content {
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
</style>
