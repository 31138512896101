<template>
    <div ref="tooltipElement">
      <slot></slot>
    </div>
  </template>

  <script>
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';

  export default {
    props: {
      content: {
        type: String,
        required: true
      }
    },
    mounted() {
      tippy(this.$refs.tooltipElement, {
        content: this.content,
      });
    }
  };
  </script>
