<template>
    <div :class="['textarea-wrapper', className]">
        <textarea class="textarea-input"
            v-model="computedValue"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="updateValue"></textarea>
        <div class="message-container">
            <span v-if="error" class="error-message">{{ error }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextareaField',
    props: {
        value: {
            type: String,
            default: ''
        },
        className: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.updateValue(newValue);
            }
        }
    },
    watch: {
        value(newValue) {
            this.computedValue = newValue;
        }
    },
    methods: {
        updateValue() {
            this.$emit('input', this.localValue);
        }
    }
};
</script>

<style scoped>
.textarea-wrapper {
    box-sizing: border-box;
    outline: none;
}

.textarea-label {
    margin: 0px;
    display: flex;
    font-size: 17px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 5px;
    backdrop-filter: none;
}

.textarea-input {
    outline: 0;
    width: 100%;
    border: 0px;
    resize: none;
    font-size: 20px;
    font-weight: 400;
    overflow: scroll;
    background: none;
    min-height: 174px;
    padding: 10px 10px;
    border-radius: 15px;
    backdrop-filter: none;
    box-sizing: border-box;
    font-family: "MWSans", sans-serif;
    overflow-y: auto;
    scrollbar-width: none;
    height: 100%;
}

.textarea-input::-webkit-scrollbar {
    display: none;
}

.message-continer {
    display: inline-flex;
    position: relative;
}

.error-message {
    color: red;
    font-size: 12px;

}

.textarea-input:-webkit-autofill,
.textarea-input:-webkit-autofill:hover,
.textarea-input:-webkit-autofill:focus,
.textarea-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #000 !important;
    caret-color: #000 !important;
}

textarea::-webkit-input-placeholder {
    text-align: left;
}

textarea:-moz-placeholder {
    text-align: left;
}

textarea::-moz-placeholder {
    text-align: left;
}

textarea:-ms-input-placeholder {
    text-align: left;
}

@media (max-width: 405px) {
    .textarea-input {
        font-size: 17px;
    }
}
</style>