import { CHAT_PAGE_HTML } from './constants'

export default {
    async startChatSocket({ state, commit }, { meetingId, username, onOpen }) {
        try {
            const chatSocket = new WebSocket(`ws://127.0.0.1:8000/ws/chat/s${meetingId}`);
            const chatWindow = window.open('', '_blank', 'width=500,height=200');
            chatWindow.document.write(CHAT_PAGE_HTML);

            commit('setChatSocket', chatSocket);
            commit('setUsername', username);
            commit('setChatWindow', chatWindow);

            chatSocket.onopen = () => {
                commit('setSocketState', 'OPEN');
                console.log('WebSocket connection established.');
                if (onOpen) onOpen();
            };

            chatSocket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                state.chatWindow.document.querySelector('#chat-log').innerHTML += '<br>' + (data.messageType === 'text' ? data.messageContent : `<img src="${data.messageContent}" />`);
            };

            chatSocket.onclose = (e) => {
                state.chatWindow.close();
                console.error('The chat socket closed unexpectedly', e);
                commit('setSocketState', 'CLOSED');
            };

            chatWindow.addEventListener('beforeunload', () => {
                console.log('Chat window closed, closing WebSocket.');
                chatSocket.close();
            });
        } catch (error) {
            console.error('Failed to initialize WebSocket connection:', error);
        }
    },

    async sendMessage({ state }, { frames, messageType }) {
        if (state.chatSocket && state.chatSocket.readyState === WebSocket.OPEN) {
            try {
                state.chatSocket.send(JSON.stringify({
                    'messageMode': state.chatMode,
                    'username': state.username,
                    'messageContent': frames,
                    'messageType': messageType,
                }));
            } catch (error) {
                console.error('Failed to send message via WebSocket:', error);
            }
        } else {
            console.error('WebSocket is not connected');
        }
    },

    sendChatMode({ commit }, chatMode) {
        commit('setChatMode', chatMode);
    },

    setInfoChat({ commit }, { meetingId, username }) {
        commit('setMeetingId', meetingId);
        commit('setUsername', username);
    }
}
