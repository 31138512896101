<template>
  <Header />
  <Main>
    <Column size="12">
      <Row>
        <h1>¡Comunicación sin barreras!</h1>
      </Row>
      <Row>
        <Column size="6">
          <TranslateOptions />
        </Column>
        <Column size="4">
          <DeviceSelection />
        </Column>
      </Row>
    </Column>
  </Main>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Main from '@/components/grid/Main.vue'
import TranslateOptions from '@/components/translateOptions/TranslateOptions.vue'
import DeviceSelection from '@/components/deviceSelection/DeviceSelection.vue'
import Row from '@/components/grid/Row.vue'
import Column from '@/components/grid/Column.vue'

export default {
  name: 'App',
  components: {
    TranslateOptions,
    DeviceSelection,
    Column,
    Header,
    Main,
    Row,
  }
}
</script>

<style>
h1 {
  font-size: 45px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
