import ParseMessage from '@/services/ParseMessage';

export default {
  async translateText({ state, commit }) {
    try {
      commit('setNotFoundWords', false)
      const messageParser = ParseMessage.replacePunctuation(state.messageToTranslate)
      const response = await fetch('https://send.sschat.online/api/words/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ text: messageParser })
      });
      const data = await response.json();

      const notFoundWords = data.not_found_words;
      if(notFoundWords.length != 0) {
        commit('setNotFoundWords', notFoundWords)
      }

      if(data.video_url) {
        const urlVideo = data.video_url;
        commit('setUrlVideo', urlVideo)
        commit('setShowPlay', true)
      }
    } catch (error) {
        console.error('Error:', error);
    }
  },
  copyUrlVideo({state}) {
    navigator.clipboard.writeText(state.urlVideo);
  },
  updateMessage({ commit }, text) {
    commit('setMessageToTranslate', text)
  },
  cleanMessage({ commit }) {
    commit('setMessageToTranslate', "")
    commit('setTextAreaError', "")
  },
  addMessage({ commit }, text) {
    commit('addMessageToTranslate', text)
  },
  updateShowPlay({ commit }, value) {
    commit('setShowPlay', value)
  },
  updateTextAreaError({ commit }, value) {
    commit('setTextAreaError', value)
  },
}