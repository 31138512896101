export default {
    verifyPunctuation(text) {
        const exclamationMatches = text.match(/!/g) || [];
        const invertedExclamationMatches = text.match(/¡/g) || [];
        const questionMatches = text.match(/\?/g) || [];
        const invertedQuestionMatches = text.match(/¿/g) || [];

        return exclamationMatches.length === invertedExclamationMatches.length && 
               questionMatches.length === invertedQuestionMatches.length;
    },
    replacePunctuation(text) {
        return text.replace(/[.,;:_+·$&/()=|@#€¬]/g, ' ');
    }
}

export function truncateLabel(label) {
    const maxLength = 15;
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
}