
export default {
    setChatSocket(state, socket) {
        state.chatSocket =  socket
    },
    setChatWindow(state, chatWindow) {
        state.chatWindow = chatWindow;
    },
    setUsername(state, value) {
        state.username = value;
    },
    setMettingId(state, value) {
        console.log(value, 'value')
        state.meetingId = value;
    },
    setSocketState(state, status) {
        state.socketState = status;
    },
    setChatMode(state, chatMode) {
        state.chatMode = chatMode;
    },
};