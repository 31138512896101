<template>
    <Overlay videoController="activate">
        <div class="video-controller__button-secondary">
            <TooltipElement content="Subir video">
                <ButtonsOverlay
                    class="upload-button"
                    :isUpload="true"
                    :accept="'video/*'"
                    @fileURL-selected="handleVideoFileURL"
                    @file-selected="handleVideoFile"
                    >
                    <svg class="video-controller__upload" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                        d="M10.9375 18.75V6.01563L6.875 10.0781L4.6875 7.8125L12.5 0L20.3125 7.8125L18.125 10.0781L14.0625 6.01563V18.75H10.9375ZM3.125 25C2.26562 25 1.52995 24.694 0.917969 24.082C0.30599 23.4701 0 22.7344 0 21.875V17.1875H3.125V21.875H21.875V17.1875H25V21.875C25 22.7344 24.694 23.4701 24.082 24.082C23.4701 24.694 22.7344 25 21.875 25H3.125Z"
                        fill="currentColor" />
                    </svg>
                </ButtonsOverlay>
            </TooltipElement>
            <TooltipElement content="Seleccionar WebCam">
                <ButtonsOverlay :onClick="toggleCameraOptions">
                    <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.71642 23.8806L8.23881 20.0597C7.9801 19.9602 7.73632 19.8408 7.50746 19.7015C7.27861 19.5622 7.05473 19.4129 6.83582 19.2537L3.28358 20.7463L0 15.0746L3.07463 12.7463C3.05473 12.607 3.04478 12.4726 3.04478 12.3433V11.5373C3.04478 11.408 3.05473 11.2736 3.07463 11.1343L0 8.80597L3.28358 3.13433L6.83582 4.62687C7.05473 4.46766 7.28358 4.31841 7.52239 4.1791C7.76119 4.0398 8 3.9204 8.23881 3.8209L8.71642 0H15.2836L15.7612 3.8209C16.0199 3.9204 16.2637 4.0398 16.4925 4.1791C16.7214 4.31841 16.9453 4.46766 17.1642 4.62687L20.7164 3.13433L24 8.80597L20.9254 11.1343C20.9453 11.2736 20.9552 11.408 20.9552 11.5373V12.3433C20.9552 12.4726 20.9353 12.607 20.8955 12.7463L23.9701 15.0746L20.6866 20.7463L17.1642 19.2537C16.9453 19.4129 16.7164 19.5622 16.4776 19.7015C16.2388 19.8408 16 19.9602 15.7612 20.0597L15.2836 23.8806H8.71642ZM12.0597 16.1194C13.2139 16.1194 14.199 15.7114 15.0149 14.8955C15.8308 14.0796 16.2388 13.0945 16.2388 11.9403C16.2388 10.7861 15.8308 9.80099 15.0149 8.98507C14.199 8.16915 13.2139 7.76119 12.0597 7.76119C10.8856 7.76119 9.89552 8.16915 9.08955 8.98507C8.28358 9.80099 7.8806 10.7861 7.8806 11.9403C7.8806 13.0945 8.28358 14.0796 9.08955 14.8955C9.89552 15.7114 10.8856 16.1194 12.0597 16.1194Z"
                            fill="currentColor" />
                    </svg>
                </ButtonsOverlay>
            </TooltipElement>
            <div v-if="showCameraOptions" class="camera-options">
                <ul>
                    <li v-for="camera in cameras" :key="camera.deviceId" @click="changeCamera(camera.deviceId)">
                        {{ truncateLabel(camera.label || `Camera ${camera.deviceId}`) }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="video-controller__cancel-record">
            <TooltipElement content="Borrar grabación">
                <button @click="resetRecording" class="button__cancel-record">
                    <img class="icon-delete" src="@/assets/icons/iconDeleteRecord.svg" alt="icono para borrar la grabación">
                </button>
            </TooltipElement>
        </div>
        <TooltipElement content="Iniciar Grabación">
            <button @click="startRecording" class="video-controller__button-record"></button>
        </TooltipElement>
    </Overlay>
</template>

<script>
import Overlay from '@/components/grid/Overlay.vue';
import TooltipElement from '@/components/tooltipElement/TooltipElement.vue'
import { truncateLabel } from '@/services/ParseMessage.js';
import { mapActions, mapGetters } from 'vuex';
import ButtonsOverlay from './elements/ButtonsOverlay.vue'

export default {
    name: 'RecordOverlay',
    components: {
        Overlay,
        ButtonsOverlay,
        TooltipElement,
    },
    computed: {
        ...mapGetters('signsTranslate', ['getCameraDevicesId']),
        cameras: {
            get() {
                return this.getCameraDevicesId;
            }
        }
    },
    data() {
        return {
            showCameraOptions: false,
        };
    },
    methods: {
        ...mapActions('signsTranslate', ['setCameraDeviceId', 'startRecording', 'resetRecording']),
        truncateLabel(label) {
            return truncateLabel(label);
        },
        async toggleCameraOptions() {
            this.showCameraOptions = !this.showCameraOptions;
        },
        changeCamera(deviceId) {
            this.showCameraOptions = false;
            this.setCameraDeviceId(deviceId)
        },
        handleVideoFile(fileVideo) {
            this.$store.commit('signsTranslate/setFileVideo', fileVideo);
        },
        handleVideoFileURL(fileUrl) {
            this.resetRecording()
            this.$store.commit('signsTranslate/setSrcVideo', fileUrl);
        },
    }
};
</script>

<style scoped>
@import "@/assets/styles/secondaryButtons.css";

.camera-options {
    position: absolute;
    bottom: 50px;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 200px;
    text-align: left;
}

.camera-options ul {
    list-style: none;
    padding: 10px;
    margin: 0;
}

.camera-options li {
    padding: 5px;
    cursor: pointer;
}

.camera-options li:hover {
    background-color: #f5f5f5;
}

.video-controller__cancel-record {
    position: absolute;
    top: 0;
    left: 0;
}

.button__cancel-record {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.video-controller__upload {
    margin-top: -2px;
}

.video-controller__button-secondary svg {
    height: 100%;
    width: 100%;
}

.video-controller__button-record {
    padding: 0;
    margin: 0;
    background-color: #FF5E00;
    height: 46px;
    width: 46px;
    border: #FFFFFF 6px solid;
    border-radius: 100%;
    cursor: pointer;
}

.video-controller__button-record:hover {
    visibility: visible;
    position: relative;
    border-radius: 100%;
}

@media (max-width: 500px) {
    .video-controller__button-record {
    width: 35px;
    height: 35px;
    border: 4px solid #FFFFFF;
  }

  .button__cancel-record img {
    height: 23px;
  }
}
</style>