export default {
  requestPermissions({ dispatch }) {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
    .then(stream => {
        stream.getTracks().forEach(track => track.stop());
      dispatch('getVideoDevices');
      dispatch('getAudioDevices');
    })
    .catch(err => {
        alert('Please grant permissions for camera and microphone to use this feature.', err);
    });
  },
  async startWebcam({ commit }, deviceId) {
    const constraints = {
      video: {
        deviceId: { exact: deviceId }
      }
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      commit('setStream', stream);

    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  },
  async getVideoDevices({ commit, dispatch }) {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      commit('setVideoDevices', videoDevices);
      if (videoDevices.length > 0) {
        commit('setSelectedVideoDeviceId', videoDevices[0].deviceId);
      dispatch('startWebcam', videoDevices[0].deviceId);
      }
    } catch (err) {
      console.error('Error enumerating devices:', err);
    }
  },
  selectCamera({ commit, dispatch }, deviceId) {
    commit('setSelectedVideoDeviceId', deviceId);
    dispatch('startWebcam', deviceId);
  },
  async startMicrophone({ commit }, deviceId) {
    const constraints = {
      audio: {
        deviceId: { exact: deviceId }
      }
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      commit('setAudioStream', stream);
    } catch (err) {
      console.error('Error accessing microphone:', err);
    }
  },
  async getAudioDevices({ commit, dispatch }) {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      commit('setAudioDevices', audioDevices);
      if (audioDevices.length > 0) {
        commit('setSelectedAudioDeviceId', audioDevices[0].deviceId);
        dispatch('startMicrophone', audioDevices[0].deviceId);
      }
    } catch (err) {
      console.error('Error enumerating audio devices:', err);
    }
  },
  selectMicrophone({ commit, dispatch }, deviceId) {
    commit('setSelectedAudioDeviceId', deviceId);
    dispatch('startMicrophone', deviceId);
  },
  setVideoRef({ commit }, videoRef) {
    commit('setVideoRef', videoRef);
  },
  setCamSend({ commit }, camSend) {
    commit('setCamSend', camSend);
  },
}