<template>
    <div :class="computedClass">
        <label v-if="label" class="input-label">{{ label }}</label>
        <textarea
            ref="textarea"
            class="input-textarea"
            v-model="textValue"
            :placeholder="placeholder"
            @blur="hideSuggestions"
            @input="handleInput"
            @focus="handleFocus" 
        ></textarea>

        <ul v-if="suggestions.length > 0" class="suggestions-list" :style="suggestionStyles">
            <li
                v-for="(suggestion, index) in suggestions"
                :key="index"
                @mousedown.prevent="selectSuggestion(suggestion)"
                class="suggestion-item"
            >
                {{ suggestion }}
            </li>
        </ul>
    </div>
    <div class="message-continer">
        <span v-if="error" class="error-message">{{ error }}</span>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'FormField',
    components: {
    },
    props: {
        className: String,
        error: String,
        label: String,
        placeholder: String,
        searchQuery: String,
    },
    computed: {
        computedClass() {
            return ['input-wrapper', this.className];
        },
        ...mapGetters('textTranslate', ['getMessage']),
        textValue: {
            get() {
                return this.getMessage;
            },
            set(value) {
                this.updateMessage(value);
            }
        }
    },
    data() {
        return {
            suggestions: [],
            suggestionStyles: {
                top: '0px',
            },
        };
    },
    created() {
        this.debouncedSearch = debounce(this.performSearch, 500);
    },
    methods: {
        ...mapActions('textTranslate', ['updateMessage']),
        handleFocus() {
            if (this.textValue.trim()) {
                this.performSearch();
                this.updateSuggestionPosition();
            }
        },
        hideSuggestions() {
            this.suggestions = [];
        },
        handleInput() {
            this.debouncedSearch();
            this.updateSuggestionPosition();
        },
        async performSearch() {
            this.$emit('update:searchQuery', this.textValue);
            this.searchInBackend(this.textValue);
        },
        async searchInBackend(query) {
            const response = await fetch(`https://send.sschat.online/api/search?query=${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (response.ok) {
                const data = await response.json();
                this.suggestions = this.sortSuggestions(query, data.results);
            } else {
                console.error('Hubo un problema con la solicitud de búsqueda:', response.statusText);
                console.error('Codigo de error:', response.status);
            }
        },
        sortSuggestions(query, results) {
            // Ordenar las palabras que empiezan con el término de búsqueda primero
            return results.sort((a, b) => {
                const aStartsWith = a.toLowerCase().startsWith(query.toLowerCase());
                const bStartsWith = b.toLowerCase().startsWith(query.toLowerCase());

                if (aStartsWith && !bStartsWith) {
                    return -1;
                }
                if (!aStartsWith && bStartsWith) {
                    return 1;
                }
                return a.localeCompare(b); // Orden alfabético secundario si ambos empiezan o no empiezan con el término de búsqueda
            });
        },
        updateSuggestionPosition() {
            this.$nextTick(() => {
                const textarea = this.$refs.textarea;
                const { top } = this.getCaretCoordinates(textarea);
                this.suggestionStyles = {
                    top: `${top + 20}px`, // Ajusta para que quede justo debajo de la línea de texto
                };
            });
        },
        getCaretCoordinates(textarea) {
            const { selectionStart } = textarea;
            const div = document.createElement('div');
            const span = document.createElement('span');

            // Estilos que emulan el textarea
            const style = window.getComputedStyle(textarea);
            for (const prop of style) {
                div.style[prop] = style[prop];
            }
            div.style.position = 'absolute';
            div.style.visibility = 'hidden';
            document.body.appendChild(div);

            div.textContent = textarea.value.substring(0, selectionStart);
            span.textContent = textarea.value.substring(selectionStart) || '.';
            div.appendChild(span);

            const { offsetTop: top, offsetLeft: left } = span;

            document.body.removeChild(div);

            return { top, left };
        },
        selectSuggestion(suggestion) {
            this.textValue = suggestion; // Actualiza el valor del textarea con la selección
            this.suggestions = []; // Vacía las sugerencias después de la selección
        },
    },
    watch: {
        textValue(newValue) {
            this.$emit('update:textValue', newValue);
        }
    },
};
function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }
</script>

<style scoped>
.input-wrapper {
    box-sizing: border-box;
    outline: none;
    position: relative;
}

.suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    max-height: 150px;
    overflow-y: auto;
    padding: 0;
    margin: 5px 0 0 0;
    list-style-type: none;
    width: 90%;
    border-radius: 10px;
    scrollbar-width: none;
    padding: 6px 0;
}

.suggestions-list::-webkit-scrollbar {
    display: none; /* Para Chrome, Safari y Opera */
}

.suggestion-item {
    padding: 10px;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.suggestion-item:hover {
    background-color: #f5f5f5;
}

.input-label {
    margin: 0px;
    display: flex;
    font-size: 17px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 5px;
    backdrop-filter: none;
}

.input-textarea {
    height: 246px;
    outline: 0;
    width: 100%;
    border: 0px;
    resize: none;
    font-size: 23.4px;
    font-weight: 400;
    overflow: scroll;
    background: none;
    min-height: 174px;
    padding: 10px 10px;
    border-radius: 15px;
    backdrop-filter: none;
    box-sizing: border-box;
    font-family: "MWSans", sans-serif;
    overflow-y: auto;
    scrollbar-width: none;
}

.input-textarea::-webkit-scrollbar {
    display: none;
}

.message-continer {
    display: inline-flex;
    position: relative;
}

.error-message {
    color: red;
    font-size: 12px;

}

.input-textarea:-webkit-autofill,
.input-textarea:-webkit-autofill:hover,
.input-textarea:-webkit-autofill:focus,
.input-textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #000 !important;
    caret-color: #000 !important;
}

textarea::-webkit-input-placeholder {
    text-align: left;
}

textarea:-moz-placeholder {
    text-align: left;
}

textarea::-moz-placeholder {
    text-align: left;
}

textarea:-ms-input-placeholder {
    text-align: left;
}

@media (max-width: 405px) {
  .input-textarea  {
    font-size: 17px;
  }

}
</style>