<template>
  <div class="select-option">
    <svg>
      <use :xlink:href="iconHref"></use>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'TranslateOptions',
  props: {
    iconType: {
      type: String,
      required: true,
      validator(value) {
        return ['hand-sign', 'voice-sound'].includes(value);
      }
    },
    inverted: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    iconHref() {
      const baseIcon = this.iconType === 'hand-sign' ? '#icon-hand-sign' : '#icon-voice-sound';
      return this.inverted ? `${baseIcon}-inverted` : baseIcon;
    }
  }
};
</script>
  
<style scoped>
  .select-option {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: #ff6900;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 10px;
      cursor: pointer;
  }

  .select-option svg {
    color: #FFFFFF;
    border: 0px;
    max-width: 80px;
    width: 100%;
    max-height: 50px;
    height: 100%;
  }

  .selected .select-option {
    background-color: #FFFFFF;
    border: 2px #ff6900 solid;
    box-sizing: border-box;
    transition: background-color 50ms;
    
  }

  .selected .select-option svg {
      color: #ff6900 !important;
  }

  .selected .select-option svg {
    color: #ff6900 !important;
  }

  #icon-hand-sign {
    width: 75% !important;
    
  }
</style>
  