<template>
  <div :class="computedClass">
    <label v-if="label" class="input-label">{{ label }}</label>
    <input
      :id="computedId"
      :name="computedName"
      :type="type"
      v-model="internalValue"
      :placeholder="placeholder"
      :class="['input', { 'input-error': error }]"
      @input="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    className: String,
    id: String,
    name: {
      type: String,
      required: true,
    },
    error: String,
    label: String,
    type: {
      type: String,
      default: 'text'
    },
    placeholder: String,
    modelValue: [String, Number],
  },
  computed: {
    computedClass() {
      return ['input-wrapper', this.className];
    },
    computedId() {
      return this.id || this.name;
    },
    computedName() {
      return [this.name];
    },
  },
  data() {
    return {
      internalValue: this.modelValue,
    };
  },
  watch: {
    modelValue(val) {
      this.internalValue = val;
    }
  },
  methods: {
    handleInput(event) {
      let value = event.target.value;
      this.$emit('update:modelValue', value);
    }
  }
};
</script>

<style scoped>
  .input-wrapper {
    width: 100%;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
  }

  .input-label {
    font-weight: 600;
    font-size: 16px;
    margin: 0px;
    margin-bottom: 5px;
    display: flex;
    color: #535151;
  }

  .input {
    display: inline-flex;
    padding: 0 10px;
    font-size: 16px;
    box-sizing: border-box;
    border: 2px solid #FF5E00;
    border-radius: 15px;
    height: 41px;
    width: 100%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #000 !important;
    caret-color: #000 !important;
  }
</style>