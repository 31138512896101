<template>
  <div class="overlay-container">
    <Header  activeItem="Señas a Texto"/>
    <div class="translation-box-container">
      <h1 class="overlay-container__title">Transforma tus señas en texto</h1>
      <GradientBorder border="active">
        <Row class="overlay-container__translation-box">
          <Column size="7" class="translation-box__signs-input">
            <div class="translation-box__video-wrapper">
              <Video
              v-if="srcVideo"
              autoplay
              :src="srcVideo"
              @video-ref-ready="handleVideoRefReady"
              />
              <Video
              v-if="!srcVideo"
              autoplay
              :deviceId="cameraId"
              @stream-ready="handleStreamReady"
              />
            <MissingCamera v-if="!cameraId" />
            <RecordOverlay v-if="!isRecording && !srcVideo" />
            <RecordingOverlay v-if="isRecording || srcVideo" />
          </div>
        </Column>
        <Column size="4" class="translation-box__text-output">
          <HeadInput :buttonAction="allowEditMessage" title="¡Copia el texto que tradujiste!" :showIcon=true>
            <TooltipElement content="Editar mensaje">
              <img class="icon-editMessage" src="@/assets/icons/iconEditMessage.svg" alt="icono para borrar el mensaje">
            </TooltipElement>
          </HeadInput>
          <GradientBorder border="disabled">
            <TextArea :error="errorMessage" :value="signsTranslate" :disabled=activeEdit placeholder="El texto traducido aparecerá aquí"
              class="text-output__textArea-signs" />
            <div class="signs-translate-buttons">
              <div v-if="showCopiedMessage" class="copied-message">
                <span>¡Copiado!</span>
              </div>
              <TooltipElement content="Copiar mensaje">
                <CustomButton @click="handleCopyClick">
                  <img src="@/assets/icons/iconCopyContent.svg" alt="Boton para copiar la traduccion de señas a texto">
                </CustomButton>
              </TooltipElement>
            </div>
          </GradientBorder>
        </Column>
      </Row>
    </GradientBorder>
    </div>
  </div>
</template>

<script>
import Row from '@/components/grid/Row.vue'
import Video from '@/components/common/Video.vue'
import Column from '@/components/grid/Column.vue'
import Header from '@/components/common/Header.vue'
import CustomButton from '@/components/common/Button.vue'
import TextArea from '@/components/textInput/TextArea.vue'
import HeadInput from '@/components/textInput/HeadInput.vue'
import GradientBorder from '@/components/common/GradientBorder.vue'
import MissingCamera from '@/components/videoOverlay/MissingCamera.vue'
import RecordOverlay from '@/components/videoOverlay/RecordOverlay.vue'
import TooltipElement from '@/components/tooltipElement/TooltipElement.vue'
import RecordingOverlay from '@/components/videoOverlay/RecordingOverlay.vue'
import { requestCameraAndAudioPermissions } from '@/services/MediaDevice.js';
import { getCamerasDevices, selectCamera } from '@/services/MediaDevice.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TranslationBox',
  components: {
    RecordingOverlay,
    GradientBorder,
    TooltipElement,
    RecordOverlay,
    MissingCamera,
    CustomButton,
    HeadInput,
    TextArea,
    Column,
    Header,
    Video,
    Row,
  },
  data() {
    return {
      showCopiedMessage: false,
    }
  },
  computed: {
    ...mapGetters('signsTranslate', ['getCameraDeviceId', 'getIsRecording', 'getSrcVideo', 'getActiveEdit', 'getSignsTranslate', 'getErrorMessage']),
    signsTranslate() {
      return this.getSignsTranslate;
    },
    errorMessage() {
      return this.getErrorMessage;
    },
    activeEdit() {
      return this.getActiveEdit;
    },
    cameraId() {
      return this.getCameraDeviceId;
    },
    isRecording() {
      return this.getIsRecording;
    },
    srcVideo() {
      return this.getSrcVideo;
    },
  },
  async mounted() {
    await requestCameraAndAudioPermissions();
    const cameras = await getCamerasDevices()
    this.$store.commit('signsTranslate/setCameraDevicesId', cameras);
    this.$store.commit('signsTranslate/setCameraDeviceId', selectCamera(cameras));
  },
  methods: {
    ...mapActions('signsTranslate', ['allowEditMessage', 'copyTextTranslate']),
    handleStreamReady(stream) {
      this.$store.commit('signsTranslate/setCameraStream', stream);
    },
    handleCopyClick() {
            this.copyTextTranslate();
            this.showCopiedMessage = true;
            setTimeout(() => {
                this.showCopiedMessage = false;
            }, 2000); // Oculta el mensaje después de 2 segundos
        },
    handleVideoRefReady(videoRef) {
      this.$store.commit('signsTranslate/setVideoRef', videoRef);
    }
  },
}
</script>

<style scoped>
@import "@/assets/styles/overlayContainer.css";

.overlay-container__title {
  font-family: 'MWSans', sans-serif;
  font-size: 40px;
  font-weight: 700;
  margin: 20px 20px;
}

.translation-box__signs-input {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.translation-box__video-wrapper {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.translation-box__text-output {
  display: flex;
  flex-direction: column;
}

.icon-editMessage {
  margin-right: 17px;
}

.text-output__textArea-signs {
  flex: 1;
}

.signs-translate-buttons {
  display: flex;
  margin: auto 10px;
  justify-content: end;
}

.copied-message {
  font-family: 'MWSans', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
  background-color: rgba(78, 78, 78, 0.7);
  color: white;
  top: 0;
  left: -10px;
  right: 0;
  box-sizing: border-box;
  padding: 0px 10px;
  border-radius: 10px;
}

.gap {
  visibility: collapse;
}

@media (max-width: 1000px) {
  .translation-box__text-output,
  .translation-box__signs-input {
    width: 100%;
  }

  .translation-box__text-output {
    margin-top: 20px;
    flex: 1;

  }
}

@media (max-width: 500px) {
  .overlay-container h1 {
    font-size: 40px;
    margin-bottom: 0px;
    margin: 0;
  }
}
</style>