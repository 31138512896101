<template>
    <Overlay>
        <div class="missing-camera">
            <img class="icon-camera-missing" src="@/assets/icons/iconCameraMissing.svg" alt="icono de camara no disponible o no conectada">
            <p class="message-warning">La cámara no esta disponible</p>
        </div>
    </Overlay>
</template>

<script>
import Overlay  from '@/components/grid/Overlay.vue';

export default {
    name: 'MissingCamera',
    components: {
        Overlay,
    }
}
</script>

<style scoped>
@import "@/assets/styles/fonts.css";

.missing-camera {

    margin-bottom: 40px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
}

.missing-camera img {
    margin: 0 auto;
    width: 40px;
}

.missing-camera p {
    margin: 6px auto;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    font-family: "MWSans", sans-serif;
}
</style>