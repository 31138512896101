<template>
    <div class="device-container">
        <video class="camera-container" id="video" ref="video" autoplay playsinline>
            <svg>
                <use xlink:href="#icon-camera-disable" fill="currentColor"></use>
            </svg>d
        </video>
        <div class="custom-select-wrapper">
            <select v-model="selectedVideoDeviceId" @change="changeCamera" class="custom-select select-cam">
                <option v-for="device in videoDevices" :key="device.deviceId" :value="device.deviceId">
                    {{ truncateLabel(device.label || `Camera ${device.deviceId}`) }}
                </option>
            </select>
        </div>

        <div class="camera-container">
            <svg>
                <use xlink:href="#icon-microphone-disable" fill="currentColor"></use>
            </svg>
        </div>
        <select v-model="selectedAudioDeviceId" @change="changeMicrophone" class="custom-select">
            <option v-for="device in audioDevices" :key="device.deviceId" :value="device.deviceId">
                {{ truncateLabel(device.label || `Microphone ${device.deviceId}`) }}
            </option>
        </select>
        <audio ref="audioTest" controls></audio>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    name: 'WebcamComponent',
    computed: {
        ...mapMutations('mediaDevice', ['setStream']),
        ...mapGetters('mediaDevice', ['stream', 'videoDevices', 'selectedVideoDeviceId', 'audioStream', 'audioDevices', 'selectedAudioDeviceId']),
        selectedVideoDeviceId: {
            get() {
                return this.$store.state.mediaDevice.selectedVideoDeviceId;
            },
            set(value) {
                this.$store.commit('mediaDevice/setSelectedVideoDeviceId', value);
            }
        },
        selectedAudioDeviceId: {
            get() {
                return this.$store.state.mediaDevice.selectedAudioDeviceId;
            },
            set(value) {
                this.$store.commit('mediaDevice/setSelectedAudioDeviceId', value);
            }
        },
    },
    watch: {
        stream(newStream) {
            if (newStream) {
                this.$refs.video.srcObject = newStream;
                this.setVideoRef(this.$refs.video);
            }
        },
        audioStream(newStream) {
            if (newStream) {
                this.$refs.audioTest = newStream;
            }
        }
    },
    mounted() {
    this.requestPermissions();
    },
    methods: {
        ...mapActions('mediaDevice', ['requestPermissions', 'selectCamera', 'selectMicrophone', 'setCamSend', 'setVideoRef']),
        changeCamera() {
            this.selectCamera(this.selectedVideoDeviceId);
        },
        changeMicrophone() {
            this.selectMicrophone(this.selectedAudioDeviceId);
        },
        truncateLabel(label) {
            const maxLength = 20;
            return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
        }
    },
};
</script>

<style scoped>
.device-container {
    display: flex;
    flex-wrap: wrap;
}

.camera-container svg {
    padding: 12px 0px;
    max-height: 40px;
    height: 100%;
    color: #ff6900;
}

.camera-container {
    box-sizing: border-box;
    width: 440px;
    border-radius: 20px;
    border:2px solid #ff6900;
    background-color: #F5F5F5;
    box-sizing: border-box;
}

.custom-select {
    box-shadow: none;
    border: 0;
    appearance: none;
    outline: 10px red;
    -webkit-appearance: none;
    margin-top: 19px;
}

.custom-select {
    background-color: #ff6900;
    color: #FFFFFF;
    font-size: 20px;
    border-radius: 20px;
    width: 440px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}

.select-cam {
    margin-top: 15px;
    margin-bottom: 17px;
}

select::-ms-expand {
  display: none;
}

option {
    background-color: #F5F5F5;
    color: #ff6900;
    border: 1px solid #ff6900;
    border-radius: 20px;
    padding: 20px;
    box-shadow: none;
}

option:hover {
    background-color: aliceblue;
}

button {
    margin-top: 10px;
}

audio {
    margin-top: 10px;
    display: none;
}
</style>