export async function getCamerasDevices() {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(device => device.kind === 'videoinput');
        return cameras;
    } catch (error) {
        console.error('Error enumerating devices:', error);
        return [];
    }
}

export function selectCamera(cameras) {
    const selectedCamera = cameras.length > 0 ? cameras[0] : null;

    if (selectedCamera) {
        return selectedCamera.deviceId
    } else {
        console.error('No se encontró ninguna cámara');
        return null;
    }
}

export async function requestCameraAndAudioPermissions() {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        console.log('Permissions granted:', stream);
    } catch (error) {
        console.error('Error accessing camera or microphone:', error);
    }
}