<template>
    <Overlay videoController="activate">
        <div class="video-controller__button-secondary">
            <TooltipElement content="Subir video">
                    <ButtonsOverlay
                    color="secondary"
                    :isUpload="true"
                    :accept="'video/*'"
                    @fileURL-selected="handleVideoFileURL"
                    @file-selected="handleVideoFile"
                    >
                    <svg class="video-controller__upload"  width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9375 18.75V6.01563L6.875 10.0781L4.6875 7.8125L12.5 0L20.3125 7.8125L18.125 10.0781L14.0625 6.01563V18.75H10.9375ZM3.125 25C2.26562 25 1.52995 24.694 0.917969 24.082C0.30599 23.4701 0 22.7344 0 21.875V17.1875H3.125V21.875H21.875V17.1875H25V21.875C25 22.7344 24.694 23.4701 24.082 24.082C23.4701 24.694 22.7344 25 21.875 25H3.125Z" fill="currentColor" />
                    </svg>
                </ButtonsOverlay>
            </TooltipElement>
        </div>
        <div class="video-controller__cancel-record">
            <TooltipElement content="Borrar grabación">
                <button @click="resetRecording" class="button__cancel-record">
                    <img class="icon-delete" src="@/assets/icons/iconDeleteRecord.svg" alt="icono para borrar la grabación">
                </button>
            </TooltipElement>
        </div>
        <div class="video-controller__controller-record">
            <ButtonsOverlay v-if="!isPause" size="controller" :onClick="pauseRecording">
                <TooltipElement content="Pausar">
                    <svg class="controller-record__pause" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 0H22V24H15V0Z" fill="currentColor"/>
                        <path d="M0 0H7V24H0V0Z" fill="currentColor"/>
                    </svg>
                </TooltipElement>
            </ButtonsOverlay>
            <ButtonsOverlay v-if="isPause" size="controller" :onClick="resumeRecording">
                <TooltipElement content="Play">
                    <svg class="controller-record__play" width="82" height="92" viewBox="0 0 82 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M81.6884 46.531C82.1039 46.2976 82.1039 45.7024 81.6884 45.4689L0.915245 0.079819C0.506316 -0.149972 9.13987e-06 0.143804 9.11946e-06 0.610871L5.15141e-06 91.3891C5.131e-06 91.8562 0.506312 92.15 0.915241 91.9202L81.6884 46.531Z" fill="currentColor"/>
                    </svg>
                </TooltipElement>
            </ButtonsOverlay>
            <ButtonsOverlay size="controller" :onClick="sendVideoSigns">
                <TooltipElement content="Enviar video">
                    <svg class="controller-record__send"  width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.2614 0.732376C27.9473 0.418153 27.5551 0.193339 27.1252 0.0810872C26.6953 -0.031165 26.2433 -0.0267986 25.8156 0.0937365L25.7881 0.102485L1.80351 7.37373C1.31634 7.51498 0.883677 7.8011 0.562982 8.19409C0.242287 8.58708 0.0487424 9.06833 0.0080559 9.57393C-0.0326306 10.0795 0.0814667 10.5855 0.335193 11.0248C0.588918 11.464 0.970264 11.8156 1.42858 12.0329L11.9668 17.0321L16.9659 27.5702C17.1654 27.9983 17.4833 28.3604 17.8819 28.6137C18.2805 28.867 18.7432 29.0011 19.2155 29C19.2867 29 19.3592 29 19.4317 28.9912C19.9369 28.951 20.4176 28.7567 20.8089 28.4347C21.2002 28.1126 21.4834 27.6783 21.6201 27.1903L28.8913 3.2057C28.895 3.19679 28.8979 3.18759 28.9001 3.1782C29.0206 2.75055 29.025 2.29851 28.9127 1.86861C28.8005 1.43871 28.5757 1.0465 28.2614 0.732376ZM19.1205 25.1107L14.9062 16.2135L20.5615 10.5582C20.8433 10.2764 21.0015 9.89431 21.0015 9.49586C21.0015 9.09742 20.8433 8.71529 20.5615 8.43355C20.2798 8.1518 19.8976 7.99352 19.4992 7.99352C19.1008 7.99352 18.7186 8.1518 18.4369 8.43355L12.7816 14.0888L3.88315 9.8733L25.7444 3.24944L19.1205 25.1107Z" fill="currentColor"/>
                    </svg>
                </TooltipElement>
            </ButtonsOverlay>
        </div>
    </Overlay>
</template>

<script>
import Overlay  from '@/components/grid/Overlay.vue';
import ButtonsOverlay from './elements/ButtonsOverlay.vue'
import { mapActions, mapGetters } from 'vuex';
import TooltipElement from '@/components/tooltipElement/TooltipElement.vue'

export default {
    name: 'RecordingOverlay',
    components: {
        Overlay,
        ButtonsOverlay,
        TooltipElement,
    },
    computed: {
    ...mapGetters('signsTranslate', ['getIsPaused']),
    isPause() {
      return this.getIsPaused;
    },
  },
    methods: {
        ...mapActions('signsTranslate', ['sendVideoSigns', 'pauseRecording', 'resumeRecording', 'resetRecording']),
        handleVideoFile(fileVideo) {
            this.$store.commit('signsTranslate/setFileVideo', fileVideo);
        },
        handleVideoFileURL(fileUrl) {
            this.resetRecording()
            this.$store.commit('signsTranslate/setSrcVideo', fileUrl);
        },

    }
}
</script>

<style >
@import "@/assets/styles/secondaryButtons.css";

.video-controller__button-secondary svg {
    height: 100%;
    width: 100%;
}

.video-controller__controller-record {
    display: flex;
    gap: 25px;
}

.video-controller__controller-record svg {
    color: #FF5E00;
}

.video-controller__cancel-record {
    position: absolute;
    top: 0;
    left: 0;
}

.button__cancel-record {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.controller-record__pause {
    margin-right: 2px;
    margin-top: 2px;
}

.controller-record__play {
    margin-left: 4px;
}

.controller-record__send {
    margin-right: 4px;
    margin-top: 6px;
}

.video-controller__controller-record .controller {
    transition: background-color 0.2s ease-in;
}

.video-controller__controller-record .controller svg {
    height:24px
}

.video-controller__controller-record .controller:hover svg {
    color: #FFFFFF;

}

@media (max-width: 500px) {
    .video-controller__controller-record button {
    width: 45px !important;
    height: 45px !important;
  }

  .controller-record__pause {
    margin-right: 0px;
    }

    .controller-record__send {
    margin-top: 0px;
}

  .button__cancel-record img {
    height: 23px;
  }
}
</style>