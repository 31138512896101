import { createRouter, createWebHistory } from 'vue-router';
import Signs from '@/views/Signs.vue';
import Home from '@/views/Home.vue';
import Chat from '@/views/Chat.vue';

const routes = [
  {
    path: '/',
    name: 'HomeApp',
    component: Home,
  },
  {
    path: '/signs',
    name: 'Signs',
    component: Signs,
  },
  {
    path: '/chat',
    name: 'ChatApp',
    component: Chat,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
