<template>
    <Row class="message-wrapper">
        <div class="wrapper-title">
            <HeadInput />
        </div>
        <div class="gradient-border-textarea">
            <Row class="input-wrapper" v-on:keypress.enter.prevent="translateTexts">
                    <TextArea placeholder="Introduce el mensaje aquí" :error="textAreaError" @keyup.enter.prevent="translateTexts" />
                    <div class="button-wrapper">
                        <TooltipElement content="Borrar mensaje">
                            <button class="delete-message" @click="cleanMessage">
                                <img src="@/assets/icons/iconDeleteMessage.svg" alt="icono para borrar el mensaje">
                            </button>
                        </TooltipElement>
                        <CustomButton :isLoading="loading" @click="translateTexts">
                            <TooltipElement content="Enviar mensaje">
                                <img v-show="!loading" src="@/assets/icons/iconSendMessage.svg"
                                alt="icono de enviar mensaje a traducir en leguaguaje de señas">
                            </TooltipElement>
                        </CustomButton>
                    </div>
            </Row>
        </div>
        <ButtonMessages />
    </Row>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Row from '@/components/grid/Row.vue'
import TextArea from './elements/textArea.vue'
import CustomButton from '@/components/common/Button.vue'
import ButtonMessages from './elements/buttonMessages.vue'
import HeadInput from './elements/headInput.vue'
import ParseMessage from '@/services/ParseMessage';
import TooltipElement from '@/components/tooltipElement/TooltipElement.vue'

export default {
    name: 'TranslateOptions',
    components: {
        TooltipElement,
        ButtonMessages,
        HeadInput,
        TextArea,
        CustomButton,
        Row
    },
    data() {
        return {
            loading: false,
            // textAreaError: '',
        };
    },
    computed: {
        ...mapState('textTranslate', ['messageToTranslate', 'notFoundWords']),
        ...mapGetters('textTranslate', ['getTextAreaError']),
        textAreaError: {
            get() {
                return this.getTextAreaError;
            },
            set(value) {
                this.updateTextAreaError(value);
            }
        }
    },
    methods: {
        ...mapActions('textTranslate', ['translateText', 'cleanMessage', 'updateTextAreaError']),
        async translateTexts() {
            if (this.messageToTranslate.trim() === '') {
                this.textAreaError = 'El campo de texto no puede estar vacío.';
                return;
            }

            if (!ParseMessage.verifyPunctuation(this.messageToTranslate)) {
                this.textAreaError = 'Los signos de puntuación deben estar completos.';
                return;
            }

            this.textAreaError = '';
            this.loading = true;
            try {
                await this.translateText();
            } finally {
                this.loading = false;
                if(this.notFoundWords != false) {
                    this.textAreaError = `Las palabras ${this.notFoundWords} no se encontraron.`
                }
            }
        },
    },
}
</script>

<style scoped>
.delete-message {
    visibility: collapse;
    display: none;
}

.message-wrapper {
    width: 100%;
    justify-content: left;
    flex: 1;
}

.wrapper-title {
    width: 94%;
    margin-bottom: 10px;
}

.gradient-border-textarea {
    position: none;
    width: 100%;
    border-radius: 12px;
}

.gradient-border-textarea:focus-within {
    position: relative;
    box-shadow: 4px 5px 10px #77a79d33;
}

.gradient-border-textarea::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(-40deg, #a7ded385, #ffffff8c);
    border-radius: inherit;
    z-index: 10000;
    pointer-events: none;
    mask: linear-gradient(#FFFFFF, #FFFFFF) content-box, linear-gradient(#FFFFFF, #FFFFFF);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    padding: 1px;
}

.input-wrapper {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.button-wrapper {
    display: flex;
    justify-content: end;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
}

.button-wrapper button {
    margin: 12px;
}

.button-wrapper button img {
    padding-right: 3px;
    padding-top: 5px;
}

@media (max-width: 405px) {
  .button-wrapper {
    justify-content: space-between;
  }

  .delete-message  {
    display: block;
    border: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    visibility:visible;
  }

  .delete-message img  {
    height: 29px;
  }
}
</style>