<template>
    <div class="translate-options">
        <Row class="select-translate-option">
            <div @click="selectOption('hand-sign')" :class="{ selected: selectedOption === 'hand-sign' }">
                <selectOption iconType="hand-sign" />
                <p class="label-text">Convertir señas <br>a texto</p>
            </div>
            <div @click="selectOption('voice-sound')" :class="{ selected: selectedOption === 'voice-sound' }">
                <selectOption iconType="voice-sound" />
                <p class="label-text">Convertir voz <br>a texto</p>
            </div>
        </Row>
        <Row>
            <Column size="12">
                <div class="input-form">
                    <FormField label="Identificador de reunión:" placeholder="Craftww-@2024!" v-model="meetingId"
                        @input="updateMeetingId" />
                    <FormField label="Nombre de usuario:" placeholder="Maria" v-model="username"
                        @input="updateUsername" />
                </div>
            </Column>
        </Row>
    </div>
    <div class="button-wrapper">
        <Button text="Conectar" @click="openChat" />
    </div>
</template>

<script>
import Row from '@/components/grid/Row.vue'
import Column from '@/components/grid/Column.vue'
import selectOption from './elements/SelectOption.vue'
import FormField from './elements/FormField'
import Button from '@/components/common/Button.vue'
import { mapActions, mapState } from 'vuex';

export default {
    name: 'TranslateOptions',
    components: {
        selectOption,
        FormField,
        Column,
        Button,
        Row
    },
    data() {
        return {
            meetingId: '',
            username: '',
            selectedOption: 'hand-sign',
            frameCount: 0,
        };
    },
    computed: {
        ...mapState('mediaDevice', ['videoRef', 'audioStream']),
        ...mapState('chatSocket', ['socketState']),
    },
    mounted() {
        this.sendChatMode(this.selectedOption);
    },
    methods: {
        ...mapActions('chatSocket', ['startChatSocket', 'sendChatMode', 'sendMessage']),
        selectOption(option) {
            this.selectedOption = option;
            this.sendChatMode(this.selectedOption);
        },
        startWebcam() {
            try {
                const videoElement = this.videoRef;
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                const captureFrame = () => {
                    if (videoElement.videoWidth > 0 && videoElement.videoHeight > 0) {
                        canvas.width = videoElement.videoWidth;
                        canvas.height = videoElement.videoHeight;
                        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                        this.frameCount++;

                        if (this.frameCount % 13 === 0) {
                            const frame = canvas.toDataURL('image/jpeg');
                            this.sendMessage({
                                'frames': frame,
                                'messageType': 'image'
                            });
                        }
                    }
                    if (this.socketState === 'OPEN') {
                        requestAnimationFrame(captureFrame);
                    } else {
                        console.log('Stopping captureFrame loop as WebSocket is not connected.');
                    }
                };
                console.log('first-send')
                requestAnimationFrame(captureFrame);
            } catch (error) {
                console.error('Error accessing webcam: ', error);
            }
        },
        arrayBufferToBase64(buffer) {
            let binary = '';
            let bytes = new Uint8Array(buffer);
            let len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },
        startAudio() {
            try {
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const mediaStreamSource = audioContext.createMediaStreamSource(this.audioStream);
                const processor = audioContext.createScriptProcessor(4096, 1, 1);

                processor.onaudioprocess = (event) => {
                    const audioData = event.inputBuffer.getChannelData(0);
                    const float32Array = new Float32Array(audioData);
                    const audioBuffer = float32Array.buffer;
                    const base64Audio = this.arrayBufferToBase64(audioBuffer);

                    this.sendMessage({
                            'frames': base64Audio,
                            'messageType': 'image'
                        })
                };
                mediaStreamSource.connect(processor);
                processor.connect(audioContext.destination);
            } catch (error) {
                console.error('Error accessing webcam: ', error);
            }
        },
        openChat() {
            if (this.selectedOption == "hand-sign") {
                this.startChatSocket({
                    'meetingId': this.meetingId,
                    'username': this.username,
                    onOpen: this.startWebcam,
                });
            } else {
                this.startChatSocket({
                    'meetingId': this.meetingId,
                    'username': this.username,
                    onOpen: this.startAudio,
                });
            }
        },
    }
};
</script>

<style scoped>
.translate-options {
    width: 600px;
    background-color: #F5F5F5;
    border-radius: 48px;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
}

.select-translate-option {
    justify-content: space-evenly;
}

.label-text {
    font-weight: 700;
    font-size: 16px;
    margin: 0px;
}

.input-form {
    margin: 0px auto;
    margin-top: 40px;
    width: 57%;
}

.input-form .label-text {
    display: flex;
}

.button-wrapper {
    max-width: 335px;
    width: 100%;
    margin: 18px auto;
    margin-bottom: 0px;
    height: 40px;
}
</style>