<template>
    <div :class="['gradient-border', border]">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'GradientBorder',
    props: {
        border: {
            type: String,
            default: 'activate'
        },
    },
}
</script>

<style scoped>

.gradient-border {
    border-radius: 12px;
    margin: 20px 20px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.gradient-border::before {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    content: "";
    padding: 1px;
    z-index: 10000;
    position: absolute;
    pointer-events: none;
    border-radius: inherit;
    mask: linear-gradient(#FFFFFF, #FFFFFF) content-box, linear-gradient(#FFFFFF, #FFFFFF);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
}

.active {
    position: relative;
}

.disabled {
    margin: 0;
    height: 100%;
    margin-top: 15px;
    padding-bottom: 10px;
}

.disabled:focus-within {
    position: relative;
    box-shadow: 4px 5px 10px #77a79d33;
}

.active::before {
    background: linear-gradient(45deg, #a7ded396, #ffffff69);
}

.disabled::before {
    background: linear-gradient(-40deg, #a7ded385, #ffffff8c);
}
</style>