<template>
  <div :class="rowClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RowGrid',
  props: {
    wrap: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rowClass() {
      return {
        row: true,
        'row-wrap': this.wrap,
        'row-no-wrap': !this.wrap,
      };
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: center;
}

.row-wrap {
  flex-wrap: wrap;
}

.row-no-wrap {
  flex-wrap: nowrap;
}
</style>
