<template>
    <div :class="columnClass">
      <slot></slot>
    </div>
  </template>

  <script>
  export default {
    name: 'ColumnGrid',
    props: {
      size: {
        type: Number,
        required: true
      }
    },
    computed: {
      columnClass() {
        return `col-${this.size}`;
      }
    }
  };
  </script>

  <style scoped>
  .col-1 { width: 8.3333%; }
  .col-2 { width: 16.6667%; }
  .col-3 { width: 20%; }
  .col-4 { width: 35.8333%; }
  .col-5 { width: 41.6667%; }
  .col-6 { width: 50%; }
  .col-7 { width: 59.3333%; }
  .col-8 { width: 66.6667%; }
  .col-9 { width: 75%; }
  .col-10 { width: 83.3333%; }
  .col-11 { width: 91.6667%; }
  .col-12 { width: 100%; }
  .column {
    padding: 0 15px;
    flex: 1;
  }
  </style>
