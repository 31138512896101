<template>
    <div :class="computedClass">
        <div class="video-container">
            <video
                ref="videoPlayer"
                :src="videoSrc"
                @play="hidePlayButton"
                @pause="showPlayButton"
                @ended="showPlayButton"
            ></video>
        </div>
        <div class="video-overlay" @click="togglePlayPause">
            <img v-if="showPlay" class="play-icon" src="@/assets/icons/buttonPlay.svg" alt="icono para dar play al video">
            <div v-if="showCopiedMessage" class="copied-message">
                ¡Copiado!
            </div>
            <div class="buttons-actions">
                <TooltipElement content="Copiar enlace de video">
                    <CustomButton
                        :color="'black'"
                        :disabled="!videoSrc"
                        @click.stop="handleCopyClick">
                        <img src="@/assets/icons/iconClipboard.svg" alt="icono para copiar link del video">
                    </CustomButton>
                </TooltipElement>
                <TooltipElement content="Descargar video">
                    <CustomButton
                        :color="'orange'"
                        :disabled="!videoSrc"
                        @click.stop="downloadVideo">
                            <img src="@/assets/icons/iconDownloadVideo.svg" alt="icono para descargar el video">
                    </CustomButton>
                </TooltipElement>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CustomButton from '@/components/common/Button.vue'
import VideoService from '@/services/VideoService'
import TooltipElement from '@/components/tooltipElement/TooltipElement.vue'


export default {
    name: 'VideoPlayer',
    components: {
        TooltipElement,
        CustomButton,
    },
    props: {
        className: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            showCopiedMessage: false
        };
    },
    computed: {
    ...mapGetters('textTranslate', ['getUrlVideo', 'getShowPlay']),
        videoSrc() {
            return this.getUrlVideo;
        },
        showPlay() {
            return this.getShowPlay;
        },
        computedClass() {
            return ['video-wrapper', this.className];
        },
    },
    methods: {
        ...mapActions('textTranslate', ['updateShowPlay', 'copyUrlVideo']),
        downloadVideo() {
            if (this.videoSrc) {
                VideoService.downloadVideo(this.videoSrc);
            }
        },
        handleCopyClick() {
            this.copyUrlVideo();
            this.showCopiedMessage = true;
            setTimeout(() => {
                this.showCopiedMessage = false;
            }, 2000); // Oculta el mensaje después de 2 segundos
        },
        togglePlayPause() {
            if (this.videoSrc) {
                    const video = this.$refs.videoPlayer;
                if (video) {
                    if (video.paused) {
                        video.play();
                    } else {
                        video.pause();
                    }
                }
            }
        },
        showPlayButton() {
            this.updateShowPlay(true)
        },
        hidePlayButton() {
            this.updateShowPlay(false)
        },
    }
};
</script>

<style scoped>
.video-wrapper {
    position: relative;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* flex-direction: column; */
}

.video-container {
    display: flex;
}

.video-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 12px;
    align-items: end;
    position: absolute;
    justify-content: end;
    box-sizing: border-box;
}

.play-icon {
    left: 0;
    width: 82px;
    width: 100%;
    height: 92px;
    margin: 0 auto;
    cursor: pointer;
    align-self: center;
    position: absolute;
}

.buttons-actions {
    display: inline-flex;
    gap: 12px;
}

video {
    width: 100%;
    /* height: 100%; */
    border-radius: 10px;
    /* display: flex; */
    /* flex-direction: column; */
}

.copied-message {
    position: relative;
    margin-right: 10px;
    background-color: rgba(78, 78, 78, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
}

@media (max-width: 405px) {
  .buttons-actions button {
    height: 30px;
    width: 30px;
    border-radius: 6px;
  }

  .buttons-actions button img {
    height: 18px;
    width: 18px;
    border-radius: 6px;
  }

  .play-icon {
    height: 30px;
    left: 0;
  }
}
</style>
