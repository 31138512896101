import { createStore } from 'vuex';
import mediaDevice from './modules/mediaDevice';
import chatSocket from './modules/chatSocket';
import textTranslate from './modules/textTranslate';
import signsTranslate from './modules/signsTranslate';

const store = createStore({
  modules: {
    mediaDevice,
    chatSocket,
    textTranslate,
    signsTranslate,
  }
});

export default store;