<template>
    <button
        :class="[size, { loading: isLoading }]"
        @click="handleClick"
        >
        <slot v-if="!isLoading"></slot>
        <!-- <svg v-else class="loading-icon" width="24" height="24" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="7"></circle>
        </svg> -->
        <div v-else class="loading-icon"></div>
    </button>
    <input
      v-if="isUpload"
      type="file"
      ref="fileInput"
      :accept="accept"
      style="display: none;"
      @change="handleFileChange"
    />
</template>

<script>
export default {
  name: 'ButtonsOverlay',
  props: {
      size: {
          type: String,
          default: 'default',
      },
      onClick: {
          type: Function,
          default: null,
      },
      isUpload: {
          type: Boolean,
          default: false,
      },
      accept: {
          type: String,
          default: ''
      }
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleClick(event) {
    if (this.isUpload) {
      this.$refs.fileInput.click();
    } else if (this.onClick) {
        this.isLoading = true;
        this.$nextTick(() => {
            this.onClick(event)
            .finally(() => {
                this.isLoading = false;
    });
});
}
      },
      handleFileChange(event) {
          const file = event.target.files[0];
          if (file) {
              const fileUrl = URL.createObjectURL(file);
              this.$emit('fileURL-selected', fileUrl);
              this.$emit('file-selected', file);
          }
      }
  },
}
</script>

<style scoped>
button {
    background-color: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: none;
    margin-top: 15px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 7px;
}

.controller {
    width: 47px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.controller:hover {
    background-color: #FF5E00 !important;
}

.loading-icon {
    border: 4px solid #FFFFFF;
    border-top: 4px solid transparent;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: relative;
    right: 1px;
    animation: spin 2s linear infinite;
}

.loading-icon {
    animation: rotate 2s linear infinite;
    margin: auto;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

button.loading {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 500px) {
    button {
    width: 30px;
    height: 30px;
    margin-top: 10px;
  }
  .loading-icon {
    left: 0px;
  }
}
</style>