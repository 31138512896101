export default {
    fileVideo: false,
    videoRef: false,
    srcVideo: false,
    cameraStream: '',
    cameraRecorder: null,
    cameraDeviceId: '',
    cameraDevicesId: '',
    isRecording: null,
    isPaused: null,
    isCancell: false,
    activeEdit: true,
    signsTranslate: '',
    errorMessage: '',
};