export default {
    setStream(state, stream) {
        state.stream = stream;
    },
    setVideoDevices(state, devices) {
        state.videoDevices = devices;
    },
    setSelectedVideoDeviceId(state, deviceId) {
        state.selectedVideoDeviceId = deviceId;
    },
    setAudioStream(state, audioStream) {
        state.audioStream = audioStream;
    },
    setAudioDevices(state, audioDevices) {
        state.audioDevices = audioDevices;
    },
    setSelectedAudioDeviceId(state, selectedAudioDeviceId) {
        state.selectedAudioDeviceId = selectedAudioDeviceId;
    },
    setVideoRef(state, videoRef) { // Añadir esta función
        state.videoRef = videoRef;
      },
};