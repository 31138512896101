<template>
  <div id="app" class="app-root">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
.app-root {
  width: 100%;
  justify-content: center;
}

html, body, #app {
  margin: 0;
  height: 100%;
}

</style>
