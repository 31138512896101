export default {
    getSrcVideo: state => state.srcVideo,
    getCameraStream: state => state.cameraStream,
    getCameraRecorder: state => state.cameraRecorder,
    getCameraDeviceId: state => state.cameraDeviceId,
    getCameraDevicesId: state => state.cameraDevicesId,
    getIsRecording: state => state.isRecording,
    getIsPaused: state => state.isPaused,
    getActiveEdit: state => state.activeEdit,
    getSignsTranslate: state => state.signsTranslate,
    getErrorMessage: state => state.errorMessage,
};