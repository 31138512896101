<template>
    <div :class="['content-overlay', videoController]">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'OverlayContent',
    props: {
        videoController: {
            type: String,
            default: 'disabled'
        },
    },
};
</script>

<style scoped>
.content-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    box-sizing: border-box;
}

.activate {
    margin: 15px;
    align-items: end;
}

@media (max-width: 500px) {
    .content-overlay {
        margin: 10px;
    }
}
</style>