<template>
    <div class="buttons">
        <button @click="setPredefinedText('hola')">Hola</button>
        <button @click="setPredefinedText('CMYK')">CMYK</button>
        <button @click="setPredefinedText('ajuste')">Ajuste</button>
        <button @click="setPredefinedText('RGB')">RGB</button>
        <button @click="setPredefinedText('envío')">Envío</button>
        <button @click="setPredefinedText('aprobado')">Aprobado</button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'buttonMessage',
    computed: {
        ...mapState('textTranslate', ['messageToTranslate']),
    },
    methods: {
        ...mapActions('textTranslate', ['addMessage']),
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        setPredefinedText(text) {
            const messageIsEmpty = this.messageToTranslate.substr(-1) == ""
            text += " "
            if (messageIsEmpty) {
                text = this.capitalizeFirstLetter(text);
            } else {
                text = " " + text
                text = text.toLowerCase()
            }
            this.addMessage(text)
        },
    },
};
</script>

<style scoped>
.buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
}

.buttons button {
    font-family: "MWSans", sans-serif;
    font-size: 16px;
    width: 134px;
    border: none;
    border-radius: 8px;
    background-color: #ffffff;
    color: #A5A5A5;
    cursor: pointer;
    border: #f5f5f5 1px solid;
    border-radius: 50px;
    padding: 4px 0px;
}

.buttons button:hover {
    box-shadow: #3c5c555e 3px 2px 5px 0.4px;
}

@media (max-width: 1250px) {
    .buttons {
        justify-content: space-between;
        gap: auto;
    }
}

@media (max-width: 405px) {
    .buttons {
        display: none;
    }
}
</style>