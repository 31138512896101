<template>
  <header ref="header" @mouseleave="conditionalHideHeader">
    <Row>
      <img src="@/assets/img/logoSigns.svg">
    </Row>
    <nav class="navbar">
      <ul class="nav-links">
        <li class="nav-item" :class="{ active: isActive('Texto a Señas') }"><a href="/">Texto a Señas</a></li>
        <li class="nav-item" :class="{ active: isActive('Señas a Texto') }"><a href="/signs">Señas a Texto</a></li>
        <li class="nav-item" :class="{ active: isActive('Chat en VIVO') }"><a href="#">Chat en VIVO</a></li>
      </ul>
    </nav>
  </header>
</template>

<script>
import Row from '@/components/grid/Row.vue';

export default {
  name: 'HeaderMain',
  components: {
    Row,
  },
  data() {
    return {
      isSmallScreen: false,
    };
  },
  props: {
    activeItem: {
      type: String,
      default: 'Texto a Señas',
    },
  },
  mounted() {
    const header = this.$refs.header;

    const handleResize = () => {
      this.isSmallScreen = window.innerHeight < 730;
      if (this.isSmallScreen) {
        header.classList.add('hide');
      } else {
        header.classList.remove('hide');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    if (this.isSmallScreen) {
      header.classList.remove('hide');
      setTimeout(() => {
        header.classList.add('hide');
      }, 2000);
    }

    document.addEventListener('mousemove', (event) => {
      if (event.clientY < 50 && this.isSmallScreen) {
        header.classList.remove('hide');
      }
    });
  },
  methods: {
    conditionalHideHeader() {
      if (this.isSmallScreen) {
        this.hideHeader();
      }
    },
    hideHeader() {
      this.$refs.header.classList.add('hide');
    },
    isActive(item) {
      return item === this.activeItem;
    },
  }
};
</script>

<style scoped>
@import "@/assets/styles/fonts.css";

header {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 1000;
  position: fixed;
  background-color: #FFFFFF;
  transition: transform 0.3s ease-in-out;
}

header.hide {
  transform: translateY(-100%);
}

header img {
  padding: 17px 0px 9px 0px;
  height: 35px;
}

.navbar {
  width: 100%;
  border-bottom: 4px solid #E8E8E8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 55px;
  padding: 0;
  margin: 0;
}

.nav-item {
  padding: 10px 15px;
  box-sizing: border-box;
}

.nav-item a {
  font-family: "MWSans", sans-serif;
  text-decoration: none;
  color: rgb(196, 181, 181);
  font-size: 18px;
  padding: 10px 0;
  position: relative;
}

.nav-item a:hover {
  color: #000000;
}

.nav-item.active {
  color: #FF5E00;
  cursor: pointer;
}

.nav-item.active a {
  color: #FF5E00;
  cursor: pointer;
}

.nav-item.active a::after {
  content: '';
  display: block;
  margin: 0 auto;
  height: 3.7px;
  width: 126%;
  background-color: #FF5E00;
  position: absolute;
  bottom: -3.9px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1260px) {
  header {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: 1000;
    position: relative;
    transition: transform 0.3s ease-in-out;
  }

  header.hide {
    transform: translateY(0);
  }
}

@media (max-width: 520px) {
  header img {
    width: 70%;
  }

  .nav-item {
    width: 100%;
    padding: 10px 0px;
}

  .nav-item a {
    font-size: 14px;
  }

  .nav-links {
    gap: 0px;
    justify-content: space-between;
    width: 100%;
  }
}
</style>