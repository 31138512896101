<template>
  <Row class="input-title">
    <h2>{{ title }}</h2>
    <button v-if="showIcon" @click="handleButton">
      <slot></slot>
    </button>
  </Row>
  <hr class="line-input-title">
</template>

<script>
import Row from '@/components/grid/Row.vue'

export default {
  name: 'HeadInput',
  components: {
    Row,
  },
  props: {
    title: {
      type: String,
      default: 'Título por Defecto'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    buttonAction: {
      type: Function,
      default: () => { }
    },
  },
  methods: {
    handleButton() {
      this.buttonAction();
    },
  },
};
</script>

<style>
.input-title {
  justify-content: space-between !important;
}

.line-input-title {
  margin: 0;
  width: 13%;
  padding: 0;
  margin-top: 3px;
  border: 1px solid #FF5E00;
}

.input-title h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  font-family: 'MWSans', sans-serif;
}

.input-title button {
  border: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
}

@media (max-width: 500px) {
  .input-title h2 {
    text-align: left;
    font-size: 20px;
  }

  .line-input-title {
    width: 55px;
  }

  .input-title button {
    display: none;
  }
}
</style>