<template>
  <Main class="main">
    <Row :wrap="false" class="page-container">
      <Row class="content-area">
      <TranslationBox/>
    </Row>
      <Row class="half-screen">
      </Row>
    </Row>
  </Main>
</template>

<script>
import Main from '@/components/grid/Main.vue'
import Row from '@/components/grid/Row.vue'
import TranslationBox from '@/components/translationBox/TranslationBox.vue'

export default {
  name: 'HomeComponent',
  components: {
    TranslationBox,
    Main,
    Row,
  },
}
</script>

<style scoped>
@import "@/assets/styles/fonts.css";

.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-area {
  flex: 1;
}

.half-screen {
  background-image: url("@/assets/img/background.png");
  background-position: -340px -25px;
  background-size: 141% auto;
  overflow: hidden;
  height: 43vh;
  width: 100%;
  background-color: #A7DED3;
}

#app {
  font-family: 'MWSans-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media (max-width: 1260px) {
  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .content-area {
    flex-grow: 1
  }

  .half-screen  {
    position: fixed;
    bottom: 0;
  }
}
</style>
