<template>
  <div class="overlay-container">
    <Header />
    <div class="translation-box-container">
      <h1 class="overlay-container__title">Transforma Tu Texto en Lenguaje de Señas</h1>
      <GradientBorder >
        <Row class="overlay-container__translation-box">
          <Column class="prompt-area" size="4">
          <textTranslate />
        </Column>
        <Column size="7" class="translation-output">
          <VideoPlayer />
        </Column>
      </Row>
    </GradientBorder>
    </div>
  </div>
</template>

<script>
import Row from '@/components/grid/Row.vue'
import Column from '@/components/grid/Column.vue'
import textTranslate from '@/components/textTranslate/textTranslate.vue'
import VideoPlayer from '@/components/videoPlayer/VideoPlayer.vue'
import Header from '@/components/common/Header.vue'
import GradientBorder from '@/components/common/GradientBorder.vue'

export default {
  name: 'TranslationBox',
  components: {
    GradientBorder,
    textTranslate,
    VideoPlayer,
    Header,
    Column,
    Row,
  },
}
</script>

<style scoped>
@import "@/assets/styles/overlayContainer.css";

.prompt-area {
  display: flex;
}

.overlay-container__title {
  font-family: 'MWSans', sans-serif;
  font-size: 40px;
  font-weight: 700;
  margin: 20px 20px;
}

.translation-output {
  display: flex;
  border-radius: 8px;
  align-items: center;
  background: #f5f5f5;
  justify-content: center;
}

@media (max-width: 1260px) {
  .col-7,
  .col-4 {
    width: 100%;
  }

  .translation-output {
    margin-top: 20px !important;
  }

  .translation-box-container {
    max-width: 500px;
    width: 100%;
  }
}


@media (max-width: 500px) {
  .overlay-container h1 {
    font-size: 24px;
    margin-bottom: 0px;
  }
}
</style>