export default {
    setMessageToTranslate(state, message) {
        state.messageToTranslate = message;
    },
    addMessageToTranslate(state, message) {
        state.messageToTranslate += message;
    },
    setUrlVideo(state, value) {
        state.urlVideo = value;
    },
    setNotFoundWords(state, value) {
        state.notFoundWords = value;
    },
    setTextAreaError(state, value) {
        state.textAreaError = value;
    },
    setShowPlay(state, showPlay) {
        state.showPlay = showPlay;
    },
};