export default {
    setSrcVideo(state, srcVideo) {
        state.srcVideo = srcVideo;
    },
    setFileVideo(state, fileVideo) {
        state.fileVideo = fileVideo;
    },
    setVideoRef(state, videoRef) {
        state.videoRef = videoRef;
    },
    clearVideoUpload(state) {
        state.srcVideo = false;
        state.isRecording = false;
        state.fileVideo = false;
        state.signsTranslate = '';
    },
    setCameraStream(state, cameraStream) {
        state.cameraStream = cameraStream;
    },
    setCameraRecorder(state, cameraRecorder) {
        state.cameraRecorder = cameraRecorder;
    },
    setCameraDeviceId(state, deviceId) {
        state.cameraDeviceId = deviceId;
    },
    setCameraDevicesId(state, devicesId) {
        state.cameraDevicesId = devicesId;
    },
    setIsRecording(state, isRecording) {
        state.isRecording = isRecording;
    },
    setIsPaused(state, isPaused) {
        state.isPaused = isPaused;
    },
    setActiveEdit(state, activeEdit) {
        state.activeEdit = activeEdit;
    },
    setSignsTranslate(state, signsTranslate) {
        state.signsTranslate = signsTranslate;
    },
    setErrorMessage(state, errorMessage) {
        state.errorMessage = errorMessage;
    },
    cleanCameraStream(state) {
        state.isRecording = false;
        state.isPaused = false;
        state.isCancell = false;
        state.recordedChunks = [];
        state.recordingBlob = null;
    }
};