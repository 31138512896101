export async function getCameraStreamById(deviceId) {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: deviceId } },
            audio: false
        });
        return stream;
    } catch (error) {
        console.error('Error al obtener el stream de la cámara con deviceId:', deviceId, error);
        return null;
    }
}

export async function saveRecording(blob) {
    if (blob) {
        console.debug('saveRecording')
        console.log('Grabación guardada:', blob);

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'recording.webm';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
}

export async function saveRecordingToServer(videoFile, isStream=false) {
    const formData = new FormData();
    if(isStream) {
        formData.append('video', videoFile, 'recording.webm');
    } else {
        formData.append('video', videoFile);
    }

    const response = await fetch('https://send.sschat.online/api/signs/', {
      method: 'POST',
      body: formData
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.error);
    }

    return result;
}

export async function stopRecordingAsPromise(state) {
    return new Promise((resolve, reject) => {
      if (!state.cameraRecorder || !state.isRecording) {
        reject('No recording in progress');
        return;
      }

      state.cameraRecorder.onstop = () => {
        try {
          const videoFile = new Blob(state.recordedChunks, { type: 'video/webm' });
          saveRecordingToServer(videoFile, true).then(resolve).catch(reject);
        } catch (error) {
          reject(error);
        }
      };

      state.cameraRecorder.stop();
    });
  }