<template>
    <Row class="input-title">
        <h2>¿Qué texto deseas traducir?</h2>
        <TooltipElement content="Borrar mensaje">
            <button @click="cleanMessage">
            <img src="@/assets/icons/iconDeleteMessage.svg" alt="icono para borrar el mensaje">
          </button>
        </TooltipElement>
    </Row>
    <hr class="line-input-title">
</template>

<script>
import { mapActions } from 'vuex';
import Row from '@/components/grid/Row.vue'
import TooltipElement from '@/components/tooltipElement/TooltipElement.vue'

export default {
  name: 'HeadInput',
  components: {
    TooltipElement,
    Row,
  },
  methods: {
      ...mapActions('textTranslate', [ 'cleanMessage']),
  },
};
</script>

<style scoped>
.input-title {
  justify-content: space-between !important;
  align-items: center; 
}

.line-input-title {
  margin: 0;
  width: 14%;
  padding: 0;
  margin-top: 4px;
  border: 1px solid #FF5E00;
}

.input-title h2 {
  margin: 0;
  font-size: 28px;
  font-weight: 400;
  font-family: 'MWSans', sans-serif;
}

.input-title button {
  border: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
}

@media (max-width: 405px) {
  .input-title h2 {
    font-size: 20px;
  }

  .input-title button {
    display: none;
  }
}
</style>